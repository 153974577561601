<template>
  <div class="row justify-content-between">
    <h2 class="dark text-center section_h2 animated fadeInDown">{{ title }}</h2>
    <tickets-aside
      :current_lang="current_lang"
      :langs="langs"
      :categories="categories"
      :min_price="min_price"
      :max_price="max_price"
      :category_titles="category_titles"
    ></tickets-aside>
    <tickets
        :current_lang="current_lang"
        :all_tickets="tickets"
      ></tickets>
  </div>
</template>

<script>
export default {
  name: "TicketIndex",
  props: ['title', 'tickets', 'current_lang', 'langs', 'categories', 'min_price', 'max_price', 'category_titles'],
  data() {
    return {
    }
  },
}
</script>