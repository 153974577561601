<template>
  <div class="ptb-4 container">
    <h3 class="black h3">{{langs.departure_date}}</h3>
    <header class="d-flex align-items-center justify-content-between">
      <h4 class="h5 mb-0">{{ langs.select_date }}</h4>
      <div>
        <a class="btn btn-outline-secondary" @click.prevent="resetQty">
          <i class="bi bi-arrow-repeat"></i>
          {{ langs.reselect }}
        </a>
      </div>
    </header>
    <div class="row mb-3">
      <div class="col-md-5">
        <label class="mb-3 h6 text-secondary">{{ langs.use_calendar }}</label>
        <blockquote class="calendar-outer">
          <div class="calendar-header">
            <div class="current-month">
              {{ calendar[0][6].getFullYear() }}-{{
                (calendar[0][6].getMonth() + 1).toString().padStart(2, "0")
              }}
            </div>
            <button class="change-month prev-month" @click.prevent="prevMonth">
              <span class="material-symbols-outlined">arrow_back_ios</span>
            </button>
            <button class="change-month next-month" @click.prevent="nextMonth">
              <span class="material-symbols-outlined">arrow_forward_ios</span>
            </button>
          </div>
          <table class="date-table">
            <thead>
              <tr class="row-day">
                <th class="cell-day">{{ langs.sun }}</th>
                <th class="cell-day">{{ langs.mon }}</th>
                <th class="cell-day">{{ langs.tue }}</th>
                <th class="cell-day">{{ langs.wed }}</th>
                <th class="cell-day">{{ langs.thu }}</th>
                <th class="cell-day">{{ langs.fri }}</th>
                <th class="cell-day">{{ langs.sat }}</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(week, j) in calendar" :key="`week${j}`" class="row-date">
                <td
                  v-for="(day, k) in week" :key="`day${j}${k}`" :class="`cell-date ${hasDay(day) ? 'selectable' : 'disabled'} ${selectedDate && isSelected(day) ? 'selected' : ''}`"
                  @click.prevent="selectDay(day)"
                  >
                  <div class="date-num">{{ day && day.getDate() }}</div>
                  <div v-show="hasDay(day)" class="price">
                    <!-- 價格 -->
                    {{ findDayData(day) && findDayData(day).salePrice.toLocaleString() }}
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </blockquote>
      </div>
      <!-- select quantity -->
      <div class="col-md-7">
        <label class="mb-3 h6 text-secondary">{{ langs.select_qty }}</label>
        <div>
          <section class="counter-box">
            <dl class="counter-message mb-0">
              <dt class="counter-name">
                <strong>
                  <!-- {{item.name}} -->
                  {{ jtrweb.api_details.ticketTypeName }}
                </strong>
              </dt>
              <dd class="counter-price">{{ findDayData(selectedDate).salePrice.toLocaleString() }}  / {{ langs.per_person }}</dd>
            </dl>
            <div class="counter">
              <button type="button" class="counter-btn" @click.prevent="changeQty(-1)">
                <i class="bi bi-dash-circle"></i>
              </button>
              <input type="text" class="counter-num" v-model="qty">
              <button type="button" class="counter-btn" @click.prevent="changeQty(1)">
                <i class="bi bi-plus-circle"></i>
              </button>
            </div>
          </section>
          <hr/>
        </div>
        <section class="counter-box mb-3">
          <dl class="counter-message mb-0">
            <dt class="counter-name">
              <strong class="text-secondary">{{ langs.total }}</strong>
            </dt>
          </dl>
          <div class="total-price red mb-0">
            {{ calculateTotal.toLocaleString() }}
          </div>
        </section>
        <hr>
        <section class="d-flex justify-content-end">
          <button type="button" class="d-flex align-items-center btn dark_red_bg ms-2" :disabled="!proceedAble" @click.prevent="goToUrl">
            <span class="material-symbols-outlined">shopping_basket</span>&nbsp;{{ selectedDate ? langs.buy_now : langs.no_date_chosen }}
          </button>
        </section>
      </div>
    </div>
  </div>
</template>

<script>

import { format, parseISO } from 'date-fns';

export default {
  name: "JtrwebOrder",
  props: ["current_lang", "langs", "available_dates", "jtrweb"],
  data() {
    return {
      currentDate: parseISO(this.available_dates[0].date),
      selectedDate: parseISO(this.available_dates[0].date),
      qty: 0
    }
  },
  computed: {
    calendar() {
      return this.generateCalendar(this.currentDate || new Date());
    },
    calculateTotal() {
      // 計算總價
      let total = 0;
      // 取得選擇的日期的價格
      let dayData = this.findDayData(this.selectedDate);
      if (dayData) {
        total = dayData.salePrice * this.qty;
      }
      return total;
    },
    proceedAble() {
      // 判斷是否可以進行下一步
      // this.selectedDate必須存在
      if (!this.selectedDate) return false;
      let proceedAble = this.calculateTotal > 0;

      // 不能大於最大值
      const maxQty = this.findDayData(this.selectedDate).remainNum;
      if (this.qty > maxQty) {
        proceedAble = false;
      }
      // 不能小於最小值
      const startNum = parseInt(this.jtrweb.api_details.startNum);
      if (this.qty < startNum) {
        proceedAble = false;
      }
      // 不能小於1
      if (this.qty < 1) {
        proceedAble = false;
      }
      return proceedAble;
    }
  },
  methods: {
    format,
    generateCalendar(date) {
      let startDate = new Date(date.getFullYear(), date.getMonth(), 1);
      let endDate = new Date(date.getFullYear(), date.getMonth() + 1, 0);
      let weeks = [];
      let week = [];
      // 填充開始日期前的空白
      for (let i = 0; i < startDate.getDay(); i++) {
        week.push(undefined);
      }
      for (let day = startDate; day <= endDate; day.setDate(day.getDate() + 1)) {
        week.push(new Date(day));
        if (week.length == 7) {
          weeks.push(week);
          week = [];
        }
      }
      // 如果最後一週還有剩餘天數，則填充結束日期後的空白並添加至週數組
      if (week.length > 0) {
        while (week.length < 7) {
          week.push(undefined);
        }
        weeks.push(week);
      }
      return weeks;
    },
    prevMonth() {
      if (!this.currentDate) return;
      let today = new Date();
      // 取得今天的年份和月份
      let currentYear = today.getFullYear();
      let currentMonth = today.getMonth();
      // 確保選擇的日期不能早於當前月份
      if (
        this.currentDate.getFullYear() > currentYear ||
        (this.currentDate.getFullYear() === currentYear &&
          this.currentDate.getMonth() > currentMonth)
      ) {
        this.currentDate.setMonth(this.currentDate.getMonth() - 1);
        this.currentDate = new Date(this.currentDate); // Trigger Vue to update the UI
      }
    },
    nextMonth() {
      if (!this.currentDate) return;
      this.currentDate.setMonth(this.currentDate.getMonth() + 1);
      this.currentDate = new Date(this.currentDate); // Trigger Vue to update the UI
    },
    hasDay(day) {
      if (!day) return false;
      // day 必須大於等於明天
      if (day < new Date()) return false;
      return this.available_dates.some((item) => {
        return item.date === format(day, 'yyyy-MM-dd');
      });

    },
    findDayData(day) {
      if (!day) return null;
      const dayData = this.available_dates.find((item) => {
        return item.date === format(day, 'yyyy-MM-dd');
      });
      return dayData;
    },
    selectDay(day) {
      if (!day) return;
      if (!this.hasDay(day)) return;
      if (this.selectedDate && format(this.selectedDate, 'yyyy-MM-dd') === format(day, 'yyyy-MM-dd')) return;
      this.selectedDate = day;
      // reset qty
      this.qty = 0;
    },
    isSelected(day) {
      if (!day) return false;
      if (!this.selectedDate) return false;
      return format(day, 'yyyy-MM-dd') === format(this.selectedDate, 'yyyy-MM-dd');
    },
    changeQty(qty) {
      this.qty += qty;
      if (this.qty < 0) {
        this.qty = 0;
      }
      // max
      const maxQty = this.findDayData(this.selectedDate).remainNum;
      console.log('maxQty', maxQty);
      if (this.qty > maxQty) {
        this.qty = maxQty;
      }
    },
    resetQty() {
      this.currentDate = parseISO(this.available_dates[0].date);
      this.selectedDate = parseISO(this.available_dates[0].date);
      this.qty = 0;
    },
    selectStartTime(index) {
      if (index !== this.selectedPlanIndex) {
        this.selectedPlanIndex = index;
      }
    },
    goToUrl() {
      window.location.href = `/${this.current_lang}/jtrweb_orders/new?selected_date=${format(this.selectedDate, 'yyyy-MM-dd')}&product_no=${this.jtrweb.product_no}&qty=${this.qty}`;
    }
  }
}
</script>