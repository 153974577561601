<template>
  <div class="ptb-4 container">
    <h3 class="black h3">{{langs.departure_date}}</h3>
    <header class="d-flex align-items-center justify-content-between">
      <h4 class="h5 mb-0">{{ langs.select_date }}</h4>
      <div>
        <a class="btn btn-outline-secondary" @click.prevent="resetQty">
          <i class="bi bi-arrow-repeat"></i>
          {{ langs.reselect }}
        </a>
      </div>
    </header>
    <div class="row mb-3">
      <div class="col-md-5">
        <label class="mb-3 h6 text-secondary">{{ langs.use_calendar }}</label>
        <blockquote class="calendar-outer">
          <div class="calendar-header">
            <div class="current-month">
              {{ calendar[0][6].getFullYear() }}-{{
                (calendar[0][6].getMonth() + 1).toString().padStart(2, "0")
              }}
            </div>
            <button class="change-month prev-month" @click.prevent="prevMonth">
              <span class="material-symbols-outlined">arrow_back_ios</span>
            </button>
            <button class="change-month next-month" @click.prevent="nextMonth">
              <span class="material-symbols-outlined">arrow_forward_ios</span>
            </button>
          </div>
          <table class="date-table">
            <thead>
              <tr class="row-day">
                <th class="cell-day">{{ langs.sun }}</th>
                <th class="cell-day">{{ langs.mon }}</th>
                <th class="cell-day">{{ langs.tue }}</th>
                <th class="cell-day">{{ langs.wed }}</th>
                <th class="cell-day">{{ langs.thu }}</th>
                <th class="cell-day">{{ langs.fri }}</th>
                <th class="cell-day">{{ langs.sat }}</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(week, j) in calendar" :key="`week${j}`" class="row-date">
                <td
                  v-for="(day, k) in week" :key="`day${j}${k}`" :class="`cell-date ${hasDay(day) ? 'selectable' : 'disabled'} ${isSelected(day) ? 'selected' : ''}`"
                  @click.prevent="selectDay(day)"
                  >
                  <div class="date-num">{{ day && day.getDate() }}</div>
                  <div v-show="hasDay(day)" class="price">
                    <!-- 價格 -->
                    {{ findDayData(day) && findDayData(day).adult_price.toLocaleString() }}
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </blockquote>
      </div>
      <div class="col-md-7">
        <label class="mb-3 h6 text-secondary">{{ langs.quantity }}</label>
        <section class="counter-box">
          <dl class="counter-message mb-0">
            <dt class="counter-name">
              <strong>{{ langs.adult }}</strong>{{ langs.adult_age }}</dt>
            <dd class="counter-price">{{findDayData(selectedDate) && findDayData(selectedDate).adult_price.toLocaleString()}}  / {{ langs.per_person }}</dd>
          </dl>
          <div class="counter">
            <button type="button" :class="`counter-btn ${adultQty <= 0 ? 'disabled' : ''}`" :data-tooltip="`${langs.tooltip_min}`" @click.prevent="changeQty('adult', -1)">
              <i class="bi bi-dash-circle"></i>
            </button>
            <input type="text" class="counter-num" v-model="adultQty">
            <button type="button" :class="`counter-btn ${adultQty >= 10 ? 'disabled' : ''}`" :data-tooltip="`${langs.tooltip_max}`" @click.prevent="changeQty('adult', 1)">
              <i class="bi bi-plus-circle"></i>
            </button>
          </div>
        </section>
        <section class="counter-box">
          <dl class="counter-message mb-0">
            <dt class="counter-name">
              <strong>{{ langs.student }}</strong>{{ langs.student_age }}</dt>
            <dd class="counter-price">{{findDayData(selectedDate) && findDayData(selectedDate).student_price.toLocaleString()}}  / {{ langs.per_person }}</dd>
          </dl>
          <div class="counter">
            <button type="button" :class="`counter-btn ${studentQty <= 0 ? 'disabled' : ''}`" :data-tooltip="`${langs.tooltip_min}`" @click.prevent="changeQty('student', -1)">
              <i class="bi bi-dash-circle"></i>
            </button>
            <input type="text" class="counter-num" v-model="studentQty">
            <button type="button" :class="`counter-btn ${studentQty >=10 ? 'disabled' : ''}`" :data-tooltip="`${langs.tooltip_max}`" @click.prevent="changeQty('student', 1)">
              <i class="bi bi-plus-circle"></i>
            </button>
          </div>
        </section>
        <section class="counter-box">
          <dl class="counter-message mb-0">
            <dt class="counter-name">
              <strong>{{ langs.child }}</strong>{{ langs.child_age }}</dt>
            <dd class="counter-price">{{findDayData(selectedDate) && findDayData(selectedDate).children_price.toLocaleString()}}  / 每人</dd>
          </dl>
          <div class="counter">
            <button type="button" :class="`counter-btn ${childQty <= 0 ? 'disabled' : ''}`" :data-tooltip="`${langs.tooltip_min}`" @click.prevent="changeQty('child', -1)">
              <i class="bi bi-dash-circle"></i>
            </button>
            <input type="text" class="counter-num" v-model="childQty">
            <button type="button" :class="`counter-btn ${childQty >= 10 ? 'disabled' : ''}`" :data-tooltip="`${langs.tooltip_max}`" @click.prevent="changeQty('child', 1)">
              <i class="bi bi-plus-circle"></i>
            </button>
          </div>
        </section>
        <hr/>
        <section
          class="counter-box mb-3">
          <!--加總欄-->
          <dl class="counter-message mb-0">
            <dt class="counter-name">
              <strong class="text-secondary">{{ langs.total }}</strong>
            </dt>
          </dl>
          <div class="total-price red mb-0">
            {{ calculateTotal.toLocaleString() }}
          </div>
        </section>
        <section class="d-flex justify-content-end">
          <button type="button" class="d-flex align-items-center btn dark_red_bg ms-2" :disabled="adultQty <= 0" @click.prevent="goToUrl">
            <span class="material-symbols-outlined">shopping_basket</span>{{ langs.buy_now }}</button>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
import { format, parseISO } from 'date-fns';

export default {
  name: "TourOrder",
  props: ['current_lang', 'langs', 'available_dates', 'tour_key'],
  data() {
    return {
      currentDate: parseISO(this.available_dates[0].date),
      selectedDate: parseISO(this.available_dates[0].date),
      adultQty: 0,
      studentQty: 0,
      childQty: 0,
    }
  },
  computed: {
    calendar() {
      return this.generateCalendar(this.currentDate);
    },
    calculateTotal() {
      return this.adultQty * this.findDayData(this.selectedDate).adult_price +
        this.studentQty * this.findDayData(this.selectedDate).student_price +
        this.childQty * this.findDayData(this.selectedDate).children_price;
    },
    selectedDateLeft() {
      return this.findDayData(this.selectedDate).tickets_left;
    }
  },
  methods: {
    format,
    generateCalendar(date) {
      let startDate = new Date(date.getFullYear(), date.getMonth(), 1);
      let endDate = new Date(date.getFullYear(), date.getMonth() + 1, 0);
      let weeks = [];
      let week = [];
      // 填充開始日期前的空白
      for (let i = 0; i < startDate.getDay(); i++) {
        week.push(undefined);
      }
      for (let day = startDate; day <= endDate; day.setDate(day.getDate() + 1)) {
        week.push(new Date(day));
        if (week.length == 7) {
          weeks.push(week);
          week = [];
        }
      }
      // 如果最後一週還有剩餘天數，則填充結束日期後的空白並添加至週數組
      if (week.length > 0) {
        while (week.length < 7) {
          week.push(undefined);
        }
        weeks.push(week);
      }
      return weeks;
    },
    prevMonth() {
      let today = new Date();
      // 取得今天的年份和月份
      let currentYear = today.getFullYear();
      let currentMonth = today.getMonth();
      // 確保選擇的日期不能早於當前月份
      if (
        this.currentDate.getFullYear() > currentYear ||
        (this.currentDate.getFullYear() === currentYear &&
          this.currentDate.getMonth() > currentMonth)
      ) {
        this.currentDate.setMonth(this.currentDate.getMonth() - 1);
        this.currentDate = new Date(this.currentDate); // Trigger Vue to update the UI
      }
    },
    nextMonth() {
      this.currentDate.setMonth(this.currentDate.getMonth() + 1);
      this.currentDate = new Date(this.currentDate); // Trigger Vue to update the UI
    },
    hasDay(day) {
      if (!day) return false;
      // day 必須大於等於明天
      if (day < new Date()) return false;
      return this.available_dates.some((date) => {
        return date.date === format(day, 'yyyy-MM-dd');
      });
    },
    findDayData(day) {
      if (!day) return null;
      return this.available_dates.find((date) => {
        return date.date === format(day, 'yyyy-MM-dd');
      });
    },
    isSelected(day) {
      if (!day) return false;
      return format(day, 'yyyy-MM-dd') === format(this.selectedDate, 'yyyy-MM-dd');
    },
    selectDay(day) {
      if (!day) return;
      if (!this.hasDay(day)) return;
      if (format(this.selectedDate, 'yyyy-MM-dd') === format(day, 'yyyy-MM-dd')) return;
      this.selectedDate = day;
      // reset qty
      this.adultQty = this.studentQty = this.childQty = 0;

    },
    changeQty(type, value) {
      if (this[`${type}Qty`] + value < 0) return;
      // 大於10人不可再增加
      // if (this[`${type}Qty`] + value > 10) return;
      // 大於剩餘票數不可再增加
      if (this.adultQty + this.studentQty + this.childQty + value > this.selectedDateLeft) return;
      this[`${type}Qty`] += value;
    },
    resetQty() {
      this.currentDate = parseISO(this.available_dates[0].date);
      this.selectedDate = parseISO(this.available_dates[0].date);
      this.adultQty = 0;
      this.studentQty = 0;
      this.childQty = 0;
    },
    goToUrl() {
      window.location.href = `/orders/new?locale=${this.current_lang}&tour_key=${this.tour_key}&adult_qty=${this.adultQty}&student_qty=${this.studentQty}&children_qty=${this.childQty}&selected_date=${format(this.selectedDate, 'yyyy-MM-dd')}`;
    }
  },
  mounted() {
  }
}
</script>