<template>
  <div class="ptb-4 container">
    <h3 class="black h3">{{langs.departure_date}}</h3>
    <header class="d-flex align-items-center justify-content-between">
      <h4 class="h5 mb-0">{{ langs.select_date }}</h4>
      <div>
        <a class="btn btn-outline-secondary" @click.prevent="resetQty">
          <i class="bi bi-arrow-repeat"></i>
          {{ langs.reselect }}
        </a>
      </div>
    </header>
    <div class="row mb-3">
      <div class="col-md-5">
        <label class="mb-3 h6 text-secondary">{{ langs.use_calendar }}</label>
        <blockquote class="calendar-outer">
          <div class="calendar-header">
            <div class="current-month">
              {{ calendar[0][6].getFullYear() }}-{{
                (calendar[0][6].getMonth() + 1).toString().padStart(2, "0")
              }}
            </div>
            <button class="change-month prev-month" @click.prevent="prevMonth">
              <span class="material-symbols-outlined">arrow_back_ios</span>
            </button>
            <button class="change-month next-month" @click.prevent="nextMonth">
              <span class="material-symbols-outlined">arrow_forward_ios</span>
            </button>
          </div>
          <table class="date-table">
            <thead>
              <tr class="row-day">
                <th class="cell-day">{{ langs.sun }}</th>
                <th class="cell-day">{{ langs.mon }}</th>
                <th class="cell-day">{{ langs.tue }}</th>
                <th class="cell-day">{{ langs.wed }}</th>
                <th class="cell-day">{{ langs.thu }}</th>
                <th class="cell-day">{{ langs.fri }}</th>
                <th class="cell-day">{{ langs.sat }}</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(week, j) in calendar" :key="`week${j}`" class="row-date">
                <td
                  v-for="(day, k) in week" :key="`day${j}${k}`" :class="`cell-date ${hasDay(day) ? 'selectable' : 'disabled'} ${selectedDate && isSelected(day) ? 'selected' : ''}`"
                  @click.prevent="selectDay(day)"
                  >
                  <div class="date-num">{{ day && day.getDate() }}</div>
                  <div v-show="hasDay(day)" class="price">
                    <!-- 價格 -->
                    {{ findDayData(day) && findDayData(day).display_amount.toLocaleString() }}
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </blockquote>
      </div>
      <!-- select quantity -->
      <div class="col-md-7">
        <label class="mb-3 h6 text-secondary">{{ langs.select_plan }}</label>
        <div v-for="(plan, index) in arranged_price_information_items" :key="`plan_${index}`">
          <h4 class="h5 mb-0">
            <label>
              <input type="radio" v-model="selectedPlanIndex" :value="index" @change.prevent="resetPlanQty(index)">
              <span :style="index !== selectedPlanIndex ? 'color:#aaa' : ''">{{ plan.plan_title }}</span>
              <!-- min max capacity -->
              <span class="fs-sm" :style="index !== selectedPlanIndex ? 'color:#aaa' : ''">({{ langs.min_qty }}: {{ plan.min_capacity_inclusive || 1 }} / {{ langs.max_qty }}: {{ plan.max_capacity_inclusive || langs.unlimited }})</span>
            </label>
          </h4>
          <section v-if="plan.start_times && plan.start_times.length > 1">
            <div>{{ langs.start_time }}</div>
            <div v-for="(start_time, j) in plan.start_times" :key="`start_time_${j}`" class="d-inline-block mr-2">
              <label>
                <input type="radio" v-model="startTimeId" :value="start_time.id" @change.prevent="selectStartTime(index)">
                {{ start_time.time }} <span v-if="start_time.title.length > 0">{{ start_time.title }}</span>
              </label>
            </div>
          </section>
          <section v-for="(item, i) in plan.unit_items" :key="`item_${i}_${i}`" class="counter-box">
            <dl class="counter-message mb-0">
              <dt class="counter-name">
                <strong :style="index !== selectedPlanIndex ? 'color:#aaa' : ''">{{item.name}}</strong>
              </dt>
              <dd class="counter-price">{{item.display_amount}}  / {{ langs.per_person }}</dd>
            </dl>
            <div class="counter">
              <button type="button" class="counter-btn" @click.prevent="changeQty(index, i, -1)">
                <i class="bi bi-dash-circle"></i>
              </button>
              <input type="text" class="counter-num" v-model="orderQties[index][i]">
              <button type="button" class="counter-btn" @click.prevent="changeQty(index, i, 1)">
                <i class="bi bi-plus-circle"></i>
              </button>
            </div>
          </section>
          <hr/>
        </div>
        <section class="counter-box mb-3">
          <dl class="counter-message mb-0">
            <dt class="counter-name">
              <strong class="text-secondary">{{ langs.total }}</strong>
            </dt>
          </dl>
          <div class="total-price red mb-0">
            {{ calculateTotal.toLocaleString() }}
          </div>
        </section>
        <hr>
        <section class="d-flex justify-content-end">
          <button type="button" class="d-flex align-items-center btn dark_red_bg ms-2" :disabled="!proceedAble" @click.prevent="goToUrl">
            <span class="material-symbols-outlined">shopping_basket</span>&nbsp;{{ selectedDate ? langs.buy_now : langs.no_date_chosen }}
          </button>
        </section>
      </div>
    </div>
  </div>
</template>

<script>

import { format, parseISO } from 'date-fns';

function modifyArray(arr, firstIndex, secondIndex, newValue, maxQty) {
    // 检查新值是否在有效范围内
    if (newValue < 0) {
        return arr; // 如果不在有效范围内，返回原始数组
    }
    // 创建新的数组以避免直接修改原始数组
    let modifiedArray = arr.map((innerArray, index) => {
        if (index === firstIndex) {
            // 当索引匹配时，修改指定的第二层元素
            let modifiedInnerArray = [...innerArray];
            modifiedInnerArray[secondIndex] = newValue;
            // 假如maxQty存在, 且arr的總和大於maxQty, 則不修改
            if (maxQty && modifiedInnerArray.reduce((a, b) => a + b, 0) > maxQty) {
              return innerArray;
            } else {
              return modifiedInnerArray;
            }
        } else {
            // 对于其他第一层元素，将其第二层元素设置为0
            return innerArray.map(() => 0);
        }
    });

    return modifiedArray;
}

export default {
  name: "TicketOrder",
  props: ["current_lang", "langs", "available_dates", "ticket"],
  data() {
    return {
      currentDate: this.available_dates[0]?.daily_availabilities[0]?.date && this.available_dates[0]?.daily_availabilities[0]?.status === "OK" ? parseISO(this.available_dates[0].daily_availabilities[0].date) : null,
      selectedDate: this.available_dates[0]?.daily_availabilities[0]?.date && this.available_dates[0]?.daily_availabilities[0]?.status === "OK" ? parseISO(this.available_dates[0].daily_availabilities[0].date) : null,
      orderQties: [],
      startTimeId: null,
      selectedPlanIndex: 0,
    }
  },
  computed: {
    calendar() {
      return this.generateCalendar(this.currentDate || new Date());
    },
    arranged_price_information_items() {
      // 將 price_information_items 轉換成一維陣列, ticket.plans中的每個plan都有一個price_information_items，裡面的unit_items(array)中的每個item都是一個物件，需要取出當中的id,name, unit_type, unit_range與display_amount
      let arranged_price_information_items = this.ticket.plans.map((plan) => {
        const items = plan.price_information_items.map((item) => {
          return item.unit_items.map((unit_item) => {
            return {
              id: unit_item.id,
              name: unit_item.name,
              unit_type: unit_item.unit_type,
              display_amount: unit_item.display_amount,
            }
          });
        })
        return {
          plan_id: plan.id,
          plan_title: plan.title,
          start_times: plan.start_times,
          max_capacity_inclusive: plan.max_capacity_inclusive,
          min_capacity_inclusive: plan.min_capacity_inclusive,
          required_fields_upon_booking: plan.required_fields_upon_booking,
          unit_items: items.flat(),
        };
      });
      return arranged_price_information_items;
    },
    calculateTotal() {
      // 計算總價
      // 將 orderQties 與 arranged_price_information_items的display_amount 逐一相乘後加總
      let total = 0;
      this.orderQties[this.selectedPlanIndex].forEach((qty, index) => {
        total += qty * this.arranged_price_information_items[this.selectedPlanIndex].unit_items[index].display_amount;
      });
      return total;
    },
    proceedAble() {
      // 判斷是否可以進行下一步
      // this.selectedDate必須存在
      if (!this.selectedDate) return false;

      let targetPlan = this.arranged_price_information_items[this.selectedPlanIndex];
      let targetOrderQties = this.orderQties[this.selectedPlanIndex];
      let proceedAble = this.calculateTotal > 0;
      let adultIndex = targetPlan.unit_items.findIndex((item) => item.unit_type === 'AGE_ADULT');
      if (adultIndex > -1) {
        // 有成人票
        let minQty = targetPlan.min_capacity_inclusive || 1;
        if (targetOrderQties[adultIndex] < minQty) {
          proceedAble = false;
        }
        // 總票數不可大於max_capacity_inclusive, 如果max_capacity_inclusive不存在，則不限制
        if (targetPlan.max_capacity_inclusive && targetOrderQties.reduce((a, b) => a + b, 0) > targetPlan.max_capacity_inclusive) {
          proceedAble = false;
        }
      } else {
        // 判斷所有票數是否在min_capacity_inclusive與max_capacity_inclusive之間，如果max_capacity_inclusive不存在，則不限制，如果min_capacity_inclusive不存在，則預設為1
        let minQty = targetPlan.min_capacity_inclusive || 1;
        let maxQty = targetPlan.max_capacity_inclusive || Infinity;
        if (targetOrderQties.some((qty) => qty < minQty || qty > maxQty)) {
          proceedAble = false;
        }
      }
      return proceedAble;

    }
  },
  methods: {
    format,
    generateCalendar(date) {
      let startDate = new Date(date.getFullYear(), date.getMonth(), 1);
      let endDate = new Date(date.getFullYear(), date.getMonth() + 1, 0);
      let weeks = [];
      let week = [];
      // 填充開始日期前的空白
      for (let i = 0; i < startDate.getDay(); i++) {
        week.push(undefined);
      }
      for (let day = startDate; day <= endDate; day.setDate(day.getDate() + 1)) {
        week.push(new Date(day));
        if (week.length == 7) {
          weeks.push(week);
          week = [];
        }
      }
      // 如果最後一週還有剩餘天數，則填充結束日期後的空白並添加至週數組
      if (week.length > 0) {
        while (week.length < 7) {
          week.push(undefined);
        }
        weeks.push(week);
      }
      return weeks;
    },
    prevMonth() {
      if (!this.currentDate) return;
      let today = new Date();
      // 取得今天的年份和月份
      let currentYear = today.getFullYear();
      let currentMonth = today.getMonth();
      // 確保選擇的日期不能早於當前月份
      if (
        this.currentDate.getFullYear() > currentYear ||
        (this.currentDate.getFullYear() === currentYear &&
          this.currentDate.getMonth() > currentMonth)
      ) {
        this.currentDate.setMonth(this.currentDate.getMonth() - 1);
        this.currentDate = new Date(this.currentDate); // Trigger Vue to update the UI
      }
    },
    nextMonth() {
      if (!this.currentDate) return;
      this.currentDate.setMonth(this.currentDate.getMonth() + 1);
      this.currentDate = new Date(this.currentDate); // Trigger Vue to update the UI
    },
    hasDay(day) {
      if (!day) return false;
      // day 必須大於等於明天
      if (day < new Date()) return false;
      // this.avaialble_dates[0].daily_availabilities includes day && status === 'OK'
      return this.available_dates[0].daily_availabilities.some((item) => {
        return format(parseISO(item.date), 'yyyy-MM-dd') === format(day, 'yyyy-MM-dd') && item.status === 'OK';
      });

    },
    findDayData(day) {
      if (!day) return null;
      return {
        display_amount: this.ticket.display_amount,
        date: day,
      }
    },
    selectDay(day) {
      if (!day) return;
      if (!this.hasDay(day)) return;
      if (this.selectedDate && format(this.selectedDate, 'yyyy-MM-dd') === format(day, 'yyyy-MM-dd')) return;
      this.selectedDate = day;
      // reset qty
    },
    isSelected(day) {
      if (!day) return false;
      if (!this.selectedDate) return false;
      return format(day, 'yyyy-MM-dd') === format(this.selectedDate, 'yyyy-MM-dd');
    },
    changeQty(index, i, qty) {
      // 重設selectedPlanIndex
      this.selectedPlanIndex = index;
      let newQty = this.orderQties[index][i] + qty;
      this.orderQties = modifyArray(this.orderQties, index, i, newQty, this.arranged_price_information_items[index].max_capacity_inclusive);
      this.startTimeId = this.arranged_price_information_items[index].start_times[0].id;
    },
    resetQty() {
      this.selectedPlanIndex = 0;
      this.orderQties = this.arranged_price_information_items.map(plan => {
        return plan.unit_items.map(item => 0)
      });
      this.currentDate = this.available_dates[0]?.daily_availabilities[0]?.date && this.available_dates[0]?.daily_availabilities[0]?.status === "OK" ? parseISO(this.available_dates[0].daily_availabilities[0].date) : null;
      this.selectedDate = this.available_dates[0]?.daily_availabilities[0]?.date && this.available_dates[0]?.daily_availabilities[0]?.status === "OK" ? parseISO(this.available_dates[0].daily_availabilities[0].date) : null;
      this.startTimeId = this.arranged_price_information_items[0].start_times[0].id;
    },
    resetPlanQty(index) {
      this.orderQties = this.arranged_price_information_items.map(plan => {
        return plan.unit_items.map(item => 0)
      });
      this.startTimeId = this.arranged_price_information_items[index].start_times[0].id;
    },
    selectStartTime(index) {
      if (index !== this.selectedPlanIndex) {
        this.selectedPlanIndex = index;
      }
    },
    goToUrl() {
      let plan_data = {
        p_id: this.arranged_price_information_items[this.selectedPlanIndex].plan_id,
        start_time_id: this.startTimeId,
        u_ids: this.arranged_price_information_items[this.selectedPlanIndex].unit_items.map((item, index) => {
          return {
            u_id: item.id,
            qty: this.orderQties[this.selectedPlanIndex][index],
          }
        }).filter((item) => item.qty > 0),
      }
      const plan_data_json = encodeURIComponent(JSON.stringify(plan_data));
      window.location.href = `/${this.current_lang}/ticket_orders/new?selected_date=${format(this.selectedDate, 'yyyy-MM-dd')}&ticket_id=${this.ticket.id}&plan_data=${plan_data_json}`;
    }
  },
  created() {
    this.orderQties = this.arranged_price_information_items.map(plan => {
      return plan.unit_items.map(item => 0)
    });
    this.startTimeId = this.arranged_price_information_items[0].start_times[0].id;
  }
}
</script>