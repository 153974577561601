<template>
  <div class="col-xl-9 col-lg-9">
    <div class="row align-items-start g-4">
      <section v-for="ticket in getTickets" :key="`ticket${ticket.id}`" class="owl_home1 col-xl-4 col-lg-4 col-md-4 col-sm-6">
        <div class="item">
          <a :href="`/${current_lang}/tickets/${ticket.id}`" data-toggle="tooltip" :title="ticket.description">
            <figure class="figure">
              <img :src="ticket.thumbnail_url" loading="lazy" style="border-radius: 1rem;">
            </figure>
            <p class="cap">{{ ticket.title }}</p>
            <div class="text-danger bold500">JPY {{ ticket.display_amount.toLocaleString() }} </div>
          </a>
        </div>
      </section>
    </div>
    <nav v-if="totalPage > 1" id="paging_nav">
      <ul class="rwd_pagination">
        <li>
          <a href="#!" aria-label="Previous" @click.prevent="prevOrNext(-1)">
            <i class="bi bi-chevron-left"></i>
          </a>
        </li>
        <li v-for="p in Array.from({length: totalPage}, (_, i) => i + 1)" :key="`page_${p}`" :class="page === p ? 'active' : ''">
          <a href="#!" @click.prevent="goTo(p)">{{ p }}</a>
        </li>
        <li>
          <a href="#!" aria-label="Next" @click.prevent="prevOrNext(1)">
            <i class="bi bi-chevron-right"></i>
          </a>
        </li>
      </ul>
    </nav>
  </div>
</template>

<script>
const perPage = 18;
const initPage = 1;

export default {
  name: "Tickets",
  props: ['current_lang', 'all_tickets'],
  data() {
    return {
      page: initPage,
      perPage,
      totalPage: Math.ceil(this.all_tickets.length / perPage) + 1,
    }
  },
  computed: {
    priceRange() {
      return this.$store.state.priceRange;
    },
    categories() {
      return this.$store.state.categories;
    },
    keyword() {
      return this.$store.state.keyword;
    },
    getTickets() {
      // 更新tours
      let tempTickets = this.all_tickets;
      // tempTours的adult_price比較是否在priceRange內
      tempTickets = tempTickets.filter(ticket => {
        return this.priceRange[0] <= ticket.display_amount && ticket.display_amount <= this.priceRange[1];
      });

      // categories
      tempTickets = tempTickets.filter(ticket => {
        let ticketCategories = ticket.categories.length > 0 ? ticket.categories : ['UNDEFINED'];
        return this.categories.some(v => ticketCategories.includes(v));
      });

      // keyword
      tempTickets = tempTickets.filter(ticket => {
        return ticket.title.toLowerCase().includes(this.keyword.toLowerCase()) || ticket.description.toLowerCase().includes(this.keyword.toLowerCase()) ;
      });

      // 更新totalPage
      this.totalPage = Math.ceil(tempTickets.length / this.perPage);
      // 加入page, per_page作為分頁
      return tempTickets.slice((this.page - 1) * this.perPage, this.page * this.perPage);
    },
  },
  methods: {
    goTo(page) {
      this.page = page;
      // this.getTours();
    },
    prevOrNext(num) {
      if (this.page + num > 0 && this.page + num <= this.totalPage) {
        this.page += num;
        // this.getTours();
      }
    }
  },

}
</script>