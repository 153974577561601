<template>
  <div class="col-xl-3 col-lg-3">
    <div class="offcanvas-lg offcanvas-start" data-bs-scroll="true" data-bs-backdrop="false" id="Sidebarshop">
      <div class="offcanvas-header">
        <h5 class="offcanvas-title">Filters</h5>
        <button class="btn-close" type="button" data-bs-dismiss="offcanvas" data-bs-target="#Sidebarshop"></button>
      </div>
      <aside class="product_list_aside pb-3">
        <section class="pt-0">
          <h3 class="aside_h3">
            {{ langs.keyword_search }}
          </h3>
          <input v-model="keyword" type="text" class="form-control ignoreReadonly" :placeholder="langs.keyword_placeholder">
        </section>
        <hr/>
        <h3 class="aside_h3">
          {{ langs.jtrweb_tags }}
        </h3>
        <ul class="no-ul-list">
          <!-- check all -->
          <li>
            <div class="d-flex align-items-center justify-content-between py-1">
              <div class="escols">
                <label class="form-check-label">
                  <input class="form-check-input mr-2" type="checkbox" v-model="isAllChecked">
                  {{ langs.check_all }}
                </label>
              </div>
            </div>
          </li>
          <li v-for="(tag, index) in jtrweb_tags" :key="`district${index}`">
            <div class="d-flex align-items-center justify-content-between py-1">
              <div class="escols">
                <label class="form-check-label">
                  <input class="form-check-input mr-2" type="checkbox" :value="tag[1]" v-model="jtrweb_options">
                  {{ tag[0] }}
                </label>
              </div>
            </div>
          </li>
        </ul>
        <hr/>
        <h3 class="aside_h3">
          {{ langs.price_range }}
        </h3>
        <div class="form-group-inner">
          <div class="rg-slider">
            <vue-slider v-model="priceRange" :min="min" :max="max" height="8px" width="92%"></vue-slider>
            <span class="float-left">JPY {{priceRange[0].toLocaleString()}} - JPY {{priceRange[1].toLocaleString()}}</span>
          </div>
        </div>
      </aside>
    </div>
  </div>


</template>

<script>
import VueSlider from 'vue-slider-component';
import 'vue-slider-component/theme/default.css';

export default {
  name: 'jtrwebAside',
  components: {VueSlider},
  props: ['current_lang', 'langs', 'jtrweb_tags', 'min_price', 'max_price'],
  data() {
    return {
      priceRange: [this.min_price, this.max_price],
      min: this.min_price,
      max: this.max_price,
      jtrweb_options: this.jtrweb_tags.map((tag) => tag[1]),
      keyword: '',
      checkAll: true
    };
  },
  methods: {
    toggleCheckAll() {
      this.isAllChecked = !this.isAllChecked;
    }
  },
  computed: {
    isAllChecked: {
      get() {
        return this.jtrweb_tags.length === this.jtrweb_options.length;
      },
      set(value) {
        if (value) {
          this.jtrweb_options = this.jtrweb_tags.map((tag) => tag[1]);
        } else {
          this.jtrweb_options = [];
        }
      },
    },
  },
  watch: {
    priceRange(newVal, oldVal) {
      this.$store.dispatch('setPriceRange', newVal);
    },
    jtrweb_options(newVal) {
      this.$store.dispatch('setJtrwebOptions', newVal);
    },
    keyword(newVal) {
      this.$store.dispatch('setKeyword', newVal);
    },
  },
  mounted() {
    this.$store.dispatch('setPriceRange', this.priceRange);
    this.$store.dispatch('setJtrwebOptions', this.jtrweb_options);
  },
}
</script>