<template>
  <div class="col-xl-9 col-lg-9">
    <div class="row align-items-start g-4">
      <section v-for="jtrweb in getJtrwebs" :key="`jtrweb${jtrweb.id}`" class="owl_home1 col-xl-4 col-lg-4 col-md-4 col-sm-6">
        <div class="item">
          <a :href="`/${current_lang}/jtrwebs/${jtrweb.product_no}`" data-toggle="tooltip" :title="jtrweb.title">
            <figure class="figure">
              <img :src="jtrweb.image" loading="lazy" style="border-radius: 1rem;">
            </figure>
            <p class="cap">{{ jtrweb.title }}</p>
            <div class="text-danger bold500">JPY {{ jtrweb.price.toLocaleString() }} </div>
          </a>
        </div>
      </section>
    </div>
    <nav v-if="totalPage > 1" id="paging_nav">
      <ul class="rwd_pagination">
        <li>
          <a href="#!" aria-label="Previous" @click.prevent="prevOrNext(-1)">
            <i class="bi bi-chevron-left"></i>
          </a>
        </li>
        <li v-for="p in Array.from({length: totalPage}, (_, i) => i + 1)" :key="`page_${p}`" :class="page === p ? 'active' : ''">
          <a href="#!" @click.prevent="goTo(p)">{{ p }}</a>
        </li>
        <li>
          <a href="#!" aria-label="Next" @click.prevent="prevOrNext(1)">
            <i class="bi bi-chevron-right"></i>
          </a>
        </li>
      </ul>
    </nav>
  </div>
</template>

<script>
const perPage = 18;
const initPage = 1;

export default {
  name: "Jtrwebs",
  props: ['current_lang', 'all_jtrwebs'],
  data() {
    return {
      page: initPage,
      perPage,
      totalPage: Math.ceil(this.all_jtrwebs.length / perPage) + 1,
    }
  },
  computed: {
    priceRange() {
      return this.$store.state.priceRange;
    },
    jtrwebOptions() {
      return this.$store.state.jtrwebOptions;
    },
    keyword() {
      return this.$store.state.keyword;
    },
    getJtrwebs() {
      // 更新tours
      let tempJtrwebs = this.all_jtrwebs;
      // tempTours的adult_price比較是否在priceRange內
      tempJtrwebs = tempJtrwebs.filter(jtrweb => {
        return this.priceRange[0] <= jtrweb.price && jtrweb.price <= this.priceRange[1];
      });

      // jtrwebOptions
      tempJtrwebs = tempJtrwebs.filter(jtrweb => {
        let jtrwebOptions = jtrweb.jtrweb_tags.length > 0 ? jtrweb.jtrweb_tags.map(tag => tag.id) : [];
        return this.jtrwebOptions.some(v => jtrwebOptions.includes(v));
      });

      // keyword
      tempJtrwebs = tempJtrwebs.filter(jtrweb => {
        return jtrweb.title.toLowerCase().includes(this.keyword.toLowerCase()) || jtrweb.description.toLowerCase().includes(this.keyword.toLowerCase()) ;
      });

      // 更新totalPage
      this.totalPage = Math.ceil(tempJtrwebs.length / this.perPage);
      // 加入page, per_page作為分頁
      return tempJtrwebs.slice((this.page - 1) * this.perPage, this.page * this.perPage);
    },
  },
  methods: {
    goTo(page) {
      this.page = page;
      // this.getTours();
    },
    prevOrNext(num) {
      if (this.page + num > 0 && this.page + num <= this.totalPage) {
        this.page += num;
        // this.getTours();
      }
    }
  },

}
</script>