<template>
    <div class="row justify-content-between">
      <h2 class="dark text-center section_h2 animated fadeInDown">{{ title }}</h2>
      <tours-aside
        :current_lang="current_lang"
        :langs="langs"
        :tour_types="tour_types"
        :districts="districts"
        :attractions="attractions"
        :min_price="min_price"
        :max_price="max_price"
      ></tours-aside>
      <tours
        :current_lang="current_lang"
        :all_tours="tours"
      ></tours>
    </div>
  </div>
</template>

<script>
  export default {
    name: "TourIndex",
    props: ['title', 'tours', 'current_lang', 'langs', 'tour_types', 'districts', 'attractions', 'min_price', 'max_price'],
  }
</script>