<template>
  <div class="row justify-content-between">
    <h2 class="dark text-center section_h2 animated fadeInDown">{{ title }}</h2>
    <jtrweb-aside
      :current_lang="current_lang"
      :langs="langs"
      :jtrweb_tags="jtrweb_tags"
      :min_price="min_price"
      :max_price="max_price"
    ></jtrweb-aside>
    <jtrwebs
        :current_lang="current_lang"
        :all_jtrwebs="jtrwebs"
      ></jtrwebs>
  </div>
</template>

<script>
export default {
  name: "JtrwebIndex",
  props: ['title', 'jtrwebs', 'current_lang', 'langs', 'jtrweb_tags', 'min_price', 'max_price'],
  data() {
    return {
    }
  },
}
</script>