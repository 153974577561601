<template>
  <div class="col-xl-9 col-lg-9">
    <div class="row align-items-start g-4">
      <section v-for="tour in getTours" :key="`tour${tour.id}`" class="col-xl-4 col-lg-4 col-md-4 col-sm-6">
        <div class="product_grid card border-0">
          <div class="shop_thumb">
            <div v-if="tour.ribbon_tag" class="sale_label text-light" :style="`background-color:${tour.ribbon_tag.color}`"> {{ tour.ribbon_tag.titles[current_lang] }}</div>
            <div class="product-slide">
              <VueSlickCarousel
                :slidesToShow="1"
                :arrows="true"
                centerPadding="0px"
                :infinite="true"
                :speed="700"
                :autoplay="false"
                :slidesToScroll="1"
                :responsive="[{breakpoint:768,settings:{arrows:true,centerMode:false,centerPadding:'15px',slidesToShow:1}},{breakpoint:480,settings:{arrows:true,centerMode:false,centerPadding:'15px',slidesToShow:1}}]"
                :dots="true">
                <div v-for="image in (tour.tour_images).sort((a, b) => {
                  if (a.position === null) return -1;
                  if (b.position === null) return 1;
                  return a.position - b.position;
                })" :key="`image${image.id}`" class="single-swipper">
                  <a :href="`/tours/${tour.key}`">
                    <img loading="lazy" :src="image.image.small.url" :alt="image.title">
                  </a>
                </div>
              </VueSlickCarousel>
            </div>
            <div class="textbox">
              <a :href="`/tours/${tour.key}`">
                <summary class="summary">{{ tour.title }}</summary>
                <div class="text-danger price">JPY {{ tour.adult_price.toLocaleString() }} </div>
              </a>
            </div>
          </div>
        </div>
      </section>
    </div>
    <nav v-if="totalPage > 1" id="paging_nav">
      <ul class="rwd_pagination">
        <li>
          <a href="#!" aria-label="Previous" @click.prevent="prevOrNext(-1)">
            <i class="bi bi-chevron-left"></i>
          </a>
        </li>
        <li v-for="p in Array.from({length: totalPage}, (_, i) => i + 1)" :key="`page_${p}`" :class="page === p ? 'active' : ''">
          <a href="#!" @click.prevent="goTo(p)">{{ p }}</a>
        </li>
        <li>
          <a href="#!" aria-label="Next" @click.prevent="prevOrNext(1)">
            <i class="bi bi-chevron-right"></i>
          </a>
        </li>
      </ul>
    </nav>
  </div>
</template>

<script>
import VueSlickCarousel from 'vue-slick-carousel';
import { parse } from 'date-fns';

const perPage = 12;
const initPage = 1;

export default {
  name: "Tours",
  props: ['current_lang', 'all_tours'],
  components: {
    VueSlickCarousel,
  },
  data() {
    return {
      page: initPage,
      perPage,
      totalPage: Math.ceil(this.all_tours.length / perPage) + 1,
    }
  },
  computed: {
    dateRange() {
      return this.$store.state.dateRange;
    },
    priceRange() {
      return this.$store.state.priceRange;
    },
    tour_types() {
      return this.$store.state.tourTypes;
    },
    districts() {
      return this.$store.state.districts;
    },
    attractions() {
      return this.$store.state.attractions;
    },
    getTours() {
      // 更新tours
      let tempTours = this.all_tours;
      // tempTours的dates轉成date object, 然後比較是否在dateRange內
      tempTours = tempTours.filter(tour => {
        const dates = tour.dates.map(({date}) => {
          const parsedDate = parse(date, 'yyyy-MM-dd', new Date());
          return parsedDate;
        });
        const dateRange = this.dateRange.map(date => parse(date, 'yyyy-MM-dd', new Date()));
        return dates.some(date => dateRange[0] <= date && date <= dateRange[1]);
      });
      // tempTours的adult_price比較是否在priceRange內
      tempTours = tempTours.filter(tour => {
        return this.priceRange[0] <= tour.adult_price && tour.adult_price <= this.priceRange[1];
      });
      // tempTours的tour_types比較是否在tour_types_options內
      tempTours = tempTours.filter(tour => {
        return tour.tour_type_ids.some(id => this.tour_types.includes(id));
      });
      // // tempTours的districts比較是否在districts_options內
      tempTours = tempTours.filter(tour => {
        return tour.district_ids.some(id => this.districts.includes(id));
      });
      // // tempTours的attractions比較是否在attractions_options內
      tempTours = tempTours.filter(tour => {
        return tour.attraction_ids.some(id => this.attractions.includes(id));
      });
      // 更新totalPage
      this.totalPage = Math.ceil(tempTours.length / this.perPage);
      // 加入page, per_page作為分頁
      return tempTours.slice((this.page - 1) * this.perPage, this.page * this.perPage);
    },
  },
  watch: {
    // dateRange(newVal, oldValue) {
    //   if (newVal !== oldValue) {
    //     this.getTours();
    //   }
    // },
    // priceRange(newVal, oldValue) {
    //   if (newVal !== oldValue) {
    //     this.getTours();
    //   }
    // },
    // tour_types(newVal, oldValue) {
    //   if (newVal !== oldValue) {
    //     this.getTours();
    //   }
    // },
    // districts(newVal, oldValue) {
    //   if (newVal !== oldValue) {
    //     this.getTours();
    //   }
    // },
    // attractions(newVal, oldValue) {
    //   if (newVal !== oldValue) {
    //     this.getTours();
    //   }
    // },
  },
  methods: {
    goTo(page) {
      this.page = page;
      // this.getTours();
    },
    prevOrNext(num) {
      if (this.page + num > 0 && this.page + num <= this.totalPage) {
        this.page += num;
        // this.getTours();
      }
    }
  },
  mounted() {
    // 更新tours
    // this.getTours();
  },

}
</script>