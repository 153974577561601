import Vue from "vue/dist/vue.esm";
// 套件
import { ValidationProvider, ValidationObserver } from "vee-validate";
import TourIndex from "./components/TourIndex.vue";
import ToursAside from "./components/ToursAside.vue";
import Tours from "./components/Tours.vue";
import TourOrder from "./components/TourOrder.vue";
import TicketIndex from "./components/TicketIndex.vue";
import TicketsAside from "./components/TicketsAside.vue";
import Tickets from "./components/Tickets.vue";
import TicketOrder from "./components/TicketOrder.vue";
import JtrwebOrder from "./components/JtrwebOrder.vue";
import JtrwebIndex from "./components/JtrwebIndex.vue";
import JtrwebAside from "./components/JtrwebAside.vue";
import Jtrwebs from "./components/Jtrwebs.vue";
// store
import store from "./store";
// 其他元件
// import Example from './components/example.vue';

// vue init
// 判斷vue-app是否存在
const vue_app = document.getElementById("vue-app");
if (vue_app) {
  document.addEventListener("DOMContentLoaded", () => {
    new Vue({
      el: "#vue-app",
      store,
    });
  });
}

// 套件使用
Vue.component("ValidationObserver", ValidationObserver);
Vue.component("ValidationProvider", ValidationProvider);

// 元件套用
Vue.component("tour-index", TourIndex);
Vue.component("tours-aside", ToursAside);
Vue.component("tours", Tours);
Vue.component("tour-order", TourOrder);

Vue.component("ticket-index", TicketIndex);
Vue.component("tickets-aside", TicketsAside);
Vue.component("tickets", Tickets);
Vue.component("ticket-order", TicketOrder);
Vue.component("jtrweb-order", JtrwebOrder);
Vue.component("jtrweb-index", JtrwebIndex);
Vue.component("jtrweb-aside", JtrwebAside);
Vue.component("jtrwebs", Jtrwebs);