import Vue from "vue/dist/vue.esm";
import Vuex from "vuex";
import axios from "axios";
import { get, set } from "lodash";

Vue.use(Vuex);

let csrf = $("meta[name=csrf-token]").attr("content");
axios.defaults.headers.common["X-CSRF-Token"] = csrf;

const store = new Vuex.Store({
  state: {
    tours: [],
    dateRange: [],
    tourTypes: [],
    districts: [],
    attractions: [],
    priceRange: [],
    categories: [],
    keyword: "",
    jtrwebOptions: []
  },
  mutations: {
    setDateRange: (state, payload) => {
      state.dateRange = payload;
      return state;
    },
    setTourTypes: (state, payload) => {
      state.tourTypes = payload;
      return state;
    },
    setDistricts: (state, payload) => {
      state.districts = payload;
      return state;
    },
    setAttractions: (state, payload) => {
      state.attractions = payload;
      return state;
    },
    setPriceRange: (state, payload) => {
      state.priceRange = payload;
      return state;
    },
    setTours: (state, payload) => {
      state.tours = payload;
      return state;
    },
    setCategories: (state, payload) => {
      state.categories = payload;
      return state;
    },
    setKeyword: (state, payload) => {
      state.keyword = payload;
      return state;
    },
    setJtrwebOptions: (state, payload) => {
      state.jtrwebOptions = payload;
      return state;
    },
  },
  actions: {
    setDateRange: (context, payload) => {
      context.commit("setDateRange", payload);
    },
    setTourTypes: (context, payload) => {
      context.commit("setTourTypes", payload);
    },
    setDistricts: (context, payload) => {
      context.commit("setDistricts", payload);
    },
    setAttractions: (context, payload) => {
      context.commit("setAttractions", payload);
    },
    setPriceRange: (context, payload) => {
      context.commit("setPriceRange", payload);
    },
    setTours: (context) => {
      context.commit("setTours", payload);
    },
    setCategories: (context, payload) => {
      context.commit("setCategories", payload);
    },
    setKeyword: (context, payload) => {
      context.commit("setKeyword", payload);
    },
    setJtrwebOptions: (context, payload) => {
      context.commit("setJtrwebOptions", payload);
    },
  },
  getters: {
    example: (state) => {
      return false;
    },
  },
});

export default store;
