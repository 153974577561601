<template>
  <div class="col-xl-3 col-lg-3">
    <div class="offcanvas-lg offcanvas-start" data-bs-scroll="true" data-bs-backdrop="false" id="Sidebarshop">
      <div class="offcanvas-header">
        <h5 class="offcanvas-title">Filters</h5>
        <button class="btn-close" type="button" data-bs-dismiss="offcanvas" data-bs-target="#Sidebarshop"></button>
      </div>
      <aside class="product_list_aside pb-3">
        <section class="pt-0">
          <h3 class="aside_h3">
            {{ langs.date_range }}
          </h3>
          <date-picker
            v-model="dateRange"
            type="datetime"
            :range="true"
            format="YYYY-MM-DD"
            value-type="format"
            input-class="form-control ignoreReadonly"
            :input-attr="{style: 'width:86%;'}"
            :disabled-date="(date) => date < subDays(new Date(), 1) || date > addDays(new Date(), 180)"
          ></date-picker>
        </section>
        <hr/>
        <section class="pt-0">
          <h3 class="aside_h3">{{ langs.tour_types }}</h3>
          <ul class="no-ul-list">
            <li v-for="(tour_type, index) in tour_types" :key="`tour_type${index}`">
              <div class="d-flex align-items-center justify-content-between py-1">
                <div class="escols">
                  <label class="form-check-label">
                    <input class="form-check-input mr-2" type="checkbox" :value="tour_type.id" v-model="tour_types_options">
                    {{ tour_type.titles[current_lang] }}
                  </label>
                </div>
              </div>
            </li>
          </ul>
        </section>
        <hr/>
        <h3 class="aside_h3">
          {{ langs.districts }}
        </h3>
        <ul class="no-ul-list">
          <li v-for="(district, index) in districts" :key="`district${index}`">
            <div class="d-flex align-items-center justify-content-between py-1">
              <div class="escols">
                <label class="form-check-label">
                  <input class="form-check-input mr-2" type="checkbox" :value="district.id" v-model="districts_options">
                  {{ district.titles[current_lang] }}
                </label>
              </div>
            </div>
          </li>
        </ul>
        <hr/>
        <h3 class="aside_h3">
          {{ langs.attractions }}
        </h3>
        <ul class="no-ul-list">
          <li v-for="(attraction, index) in attractions" :key="`attraction${index}`">
            <div class="d-flex align-items-center justify-content-between py-1">
              <div class="escols">
                <label class="form-check-label">
                  <input class="form-check-input mr-2" type="checkbox" :value="attraction.id" v-model="attractions_options">
                  {{ attraction.titles[current_lang]  }}
                </label>
              </div>
            </div>
          </li>
        </ul>
        <hr/>
        <h3 class="aside_h3">
          {{ langs.price_range }}
        </h3>
        <div class="form-group-inner">
          <div class="rg-slider">
            <vue-slider v-model="priceRange" :min="min" :max="max" height="8px" width="92%"></vue-slider>
            <span class="float-left">JPY {{priceRange[0].toLocaleString()}} - JPY {{priceRange[1].toLocaleString()}}</span>
          </div>
        </div>
      </aside>
    </div>
  </div>


</template>

<script>

import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import {subDays, addDays} from 'date-fns';
import VueSlider from 'vue-slider-component';
import 'vue-slider-component/theme/default.css';
import {format} from 'date-fns';

const dateRange = [format(new Date(), 'yyyy-MM-dd'), format(addDays(new Date(), 60), 'yyyy-MM-dd')];

export default {
  name: 'toursAside',
  components: {DatePicker, VueSlider},
  props: ['current_lang', 'langs', 'tour_types', 'districts', 'attractions', 'min_price', 'max_price'],
  data() {
    return {
      dateRange,
      priceRange: [this.min_price, this.max_price],
      min: this.min_price,
      max: this.max_price,
      tour_types_options: this.tour_types.map(t => t.id),
      districts_options: this.districts.map(d => d.id),
      attractions_options: this.attractions.map(a => a.id),
    };
  },
  methods: {
    subDays,
    addDays
  },
  watch: {
    dateRange(newVal) {
      this.$store.dispatch('setDateRange', newVal);
    },
    priceRange(newVal) {
      this.$store.dispatch('setPriceRange', newVal);
    },
    tour_types_options(newVal) {
      console.log('tour_types_options', newVal)
      this.$store.dispatch('setTourTypes', newVal);
    },
    districts_options(newVal) {
      this.$store.dispatch('setDistricts', newVal);
    },
    attractions_options(newVal) {
      this.$store.dispatch('setAttractions', newVal);
    },
  },
  mounted() {
    this.$store.dispatch('setPriceRange', this.priceRange);
    this.$store.dispatch('setDateRange', this.dateRange);
    this.$store.dispatch('setTourTypes', this.tour_types_options);
    this.$store.dispatch('setDistricts', this.districts_options);
    this.$store.dispatch('setAttractions', this.attractions_options);
  },
}
</script>